// =================================
// LABEL ANIMATED
.vfl-label {
    right:auto;
    @include borderR(4px);
    z-index: 1;
    font-size: 0.8rem;
  }

  .vfl-has-label{
    position: relative;
    display: flex !important;
    width: 100% !important;
    flex-direction: column;
  }

  .vfl-label-on-input {
    display: flex;
    width: auto;
    right: auto !important;
    top: -1em !important;
    font-weight: unset;
    background-color: $labelBg;
    padding: 2px 6px;
    color: $labelTxt !important;
  }

  .vfl-label-on-focus {
    color: $labelTxt;
  }

//   .vfl-label + input {
//     border: 0;
//     // border-bottom: transparent !important;
//     transition: none;
//     //transition: border 0.2s;
//   }

//   .vfl-label-on-focus + input {
//     border-bottom: 0px !important;
//   }
