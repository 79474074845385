.text-center{
    text-align: center;
}

.text-right{
    text-align: right;
}


 // Erro form
 .error-form {
    //display: none;
    width: 100%;
    background-color: transparent;
    margin-top: 0;
    padding: 4px 10px 1px 0;
    border-radius: 5px;
    position: relative;
    z-index: 1;
    // color: #8F8F8F !important;
    color: #FF7D7D !important;
    display: block;
    text-align: left;
    font-size: .95rem;
    // &.show{
    //     display: block;
    // }
    // span {
    //     display: inline-block;
    //     padding: 2px 0;
    //     font-size: 12px !important;
    //     line-height:14px !important;
    //     font-family: Arial, Helvetica, sans-serif;
    //     font-weight: bold;
    //     margin-bottom: 0;
    //     color: $error !important;
    // }
    // &.optin {
    //     margin-top: 0;
    //     padding: 4px 17px 2px;
    // }
}

.hairline-up{
    border-top: 1px solid #282828;
}
.hairline-bottom{
    border-bottom: 1px solid #282828;
}


.only-desk{
    display: block !important;
    @media (max-width:$mobile) {
        display: none !important;
    }
}
    .only-desk-flex{
        display: flex !important;
        @media (max-width:$mobile) {
            display: none !important;
        }
    }
    .only-desk-flex--lg{
        display: flex !important;
        @media (max-width:$screenLG) {
            display: none !important;
        }
    }

.only-mob{
    display: none !important;
    @media (max-width:$mobile) {
        display: block !important;
    }
}
    .only-mob-flex{
        display: none !important;
        @media (max-width:$mobile) {
            display: flex !important;
        }
    }.only-mob-flex--lg{
        display: none !important;
        @media (max-width:$screenLG) {
            display: flex !important;
        }
    }
