// VARIAVEIS
@import 'variables';
// MIXINS
@import 'mixins';
// LABEL ANIMADO DOS FORMS
@import 'floatLabel';
// INPUT
@import 'input';
// Checkbox
@import 'form-check';
// forms
@import 'forms';
// MODAL
@import 'modal';
// SELECT
@import 'select';
// HELPERS
@import 'helpers';


* {
    outline: none;
}

//ScrollBar browser
::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-track {
    border-radius: 0 !important;
    background-color: $corScrollbarBack;
}

::-webkit-scrollbar-thumb {
    background-color: $corScrollbar;
    border-radius: 5px;
    border: 3px solid transparent;
}

//HELPERS
.opacity0{
    opacity: 0;
}


// HTML BODY
html {
    position: relative;
    min-height: 100%;
    font-size: 16px;
    @media (max-width: 1120px) {
        font-size: 14px;
    }
}

body {
    background-color: $bgBody;
    overflow-x: hidden;
    font-family: $font !important;
    font-size: 1.0625rem;//17
    line-height: 1.2;
    p, a, pre, dd {
        color: $corText;
        font-size: $fontSize;
        font-family: $fontB;
        font-weight: 400;
        margin: 0;
        line-height: 1.4;
        @media (max-width: $mobile) {
            // font-size: $fontSize - .2;
            // line-height: 1.3;
            text-align: left;
        }
    }
    p{
        a{
            color: inherit;
        }
    }
    a:not([href]) {
        cursor: pointer;
    }
}

ol, ul, dl{
    margin: 0 !important;
    padding: 0;
    list-style: none !important;
}

// .row > *{
//     padding: 0;
// }

form{
    width: 100%;
}

// #### TITULOS ####
.title-back{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: calc(1.5rem * 0.5);
    padding-right: calc(1.5rem * 0.5);
    padding-bottom: 30px;
    gap: 8px;
    width: 100%;
}

h1,
h2,
h3,
h4 {
    color: $corTitle;
    text-align: center;
    text-transform: unset;
    font-weight: unset;
}

h1 {
    padding: 0;
    margin: 0;
    font-size: $fontTitle;
    @media (max-width: $mobile) {
        //font-size: $fontTitle - 4;
        // padding: 15px 0;
    }
}

h2 {
    font-size: calc(#{$fontTitle} - 0.4rem);
    padding: 0;
    margin: 0;
    @media (max-width: $mobile) {
        //font-size: $fontTitle - 8;
    }
}

h3 {
    font-size: calc(#{$fontTitle} - 0.8rem);
    @media (max-width: $mobile) {
        //font-size: $fontTitle - 8;
    }
}

h5 {
    font-size: $fontSize;
    color: $cor2;
    font-size: $fontTitle - 8;
    @media (max-width: $mobile) {
        font-size: $fontTitle - 12;
    }
}

.row{
    width: 100%;
    margin: 0;
}

.site--wrapper{
    display: flex;
    flex-direction: column;
    margin: 0;
    min-height: 100vh;
    overflow-x: hidden;
    padding-top: $menuHeight + 20px;
}

.container{
    max-width: $maxWidth;
    width: 100%;
    margin: 0 auto;
    padding: 0;
    @media (max-width: $mobile){

    }
}


div[class^="page--"] {
    width: 100%;
    //min-height: 45vh;
    padding-top: 0px;
    position: relative;
    @media (min-width:$mobile) {
        // padding-top: $menuHeight;
        //min-height: calc(100vh - (#{$bannerHeight}));//100vh - banner - menu
        position: relative;
        >.container{
            // padding-bottom: 50px;
        }
    }

}

section {
    width: 100%;
}


// #### IMAGENS ####
img {
    width: auto;
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
}


// #### BOTÕES ####
.btn{
    @include btn-primary;
    &:disabled{
        cursor: no-drop;
        // opacity: .65;
        // filter: grayscale(90%);
        color: #828282;
        background: #333;
    }
    &.btn-no-bg{
        background: transparent;
        padding: 0;
        min-width: unset !important;
    }
    &.btn-cinza{
        background: #282828;
    }
    &.btn-no-min{
        min-width: unset !important;
    }
    &.btn-buscar{
        min-width: unset !important;
        position: relative;
        left: -10px;
    }
}

.btn-group{
    position: relative;
}

// #### PAGINACAO GLOBAL ####
.pagination{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px !important;
    .page-item{
        padding: 5px !important;
    }
    .page-link:first-child{
        // border-radius: 0;
    }
    .page-item.active .page-link{
        background-color: transparent;
        border: none !important;
        font-family: $font;
        color: $cor1;
    }
    .page-link{
        color: $white;
        border-radius: 5px;
        background-color: transparent;
        border: none !important;
        &:focus{
            box-shadow: none;
        }
        .sr-only{
            display: none !important;
        }
    }
}

// #### FORMS GLOBAL ####

.wrapper-box{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: unset;
    border: 1px solid #282828;
    background: $cor3;
    // box-shadow: 0 1px 2px rgba(0,0,0,.05), 0 4px 5px rgba(0,0,0,.05);
    border-radius: 18px;
    padding: 30px 6px;
    // @media (max-width: $mobile){
    //     padding: 15px;
    // }
    &.no-padding{
        padding: 0 !important;
    }
}

// #### ESTILOS CARTÕES ####
.empty-card{
    background: linear-gradient(135.13deg, #7DFFB4 10.46%, #4244FF 127.81%);
    max-width: 350px;
    width: 100%;
    height: 187px;
    border-radius: 20px;
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
}
.content-card{
    background: linear-gradient(130.69deg, #FFB929 -1.51%, #FF7DC1 98.48%);
    max-width: 350px;
    width: 100%;
    height: 187px;
    border-radius: 20px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 18px;
    margin: 0 auto;
    .btn-limpar-consulta{
        position: absolute;
        top: 18px;
        right: 18px;
        width: 33px;
        height: 33px;
        border-radius: 50%;
        background: rgba(#282828, .3);
        img{
            margin-top: 5px;
        }
    }
    .btn-sacar{
        position: absolute;
        top: 18px;
        right: 18px;
        width: 134px;
        height: 46px;
        border-radius: 30px;
        background: rgba(#171717, 1);
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        &.disabled {
            pointer-events: none;
            cursor: default;
            color: #828282;
            background: #333;
        }
        img{
            max-width: 24px;
            margin: 0px;
        }
    }
    .infos-card{
        .text-valor{
            font-size: 1.2rem;
        }
    }
    .codigo-card{
        .text-codigo{
            font-size: 1.2rem;
        }
    }
}

// #### ESTILOS MODAIS GLOBAIS ####
.img-status-modal-msg{
    padding: 30px;
}
.content-modal-msg{
    padding: 0 30px 30px 30px;
    p{
        font-size: 2rem;
        line-height: 1;
        text-align: center;
    }
}
.btn-ok-modal{
    padding: 30px;
    p{
        color: #88D9D6 !important;
    }
}

.wrapper-card{
    padding-left: calc(1.5rem * 0.5);
    padding-right: calc(1.5rem * 0.5);
}
