.form-check-input[type=checkbox]{
    border: 2px solid $cor1 !important;
    border-radius: 0.25em !important;
    height: unset !important;
    box-shadow: none !important;
    &:checked{
        box-shadow: none !important;
        outline: none !important;
        background-color: #403620 !important;
        border-color: #403620 !important;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23FFB929' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e") !important;
    }
}

label{
    padding: 9px 0 0;
    color: $white !important;
    padding-top: 8px;
    font-family: $fontB;
    font-size: calc($fontSize - .1rem);
    margin-left: 7px;
    line-height: normal;
    font-weight: 400;
}
