input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }


    /* Firefox */

    input[type=number] {
        -moz-appearance: textfield;
    }

    input[type="text"]:disabled,
    input[readonly]:not(.datepicker) {
        background: $inputDisabled !important;
        cursor: no-drop;
        -webkit-text-fill-color: $inputcolor !important; // Safari
        -webkit-opacity: 1 !important;
        color: $inputcolor;
    }

    input[type="password"] {
        text-transform: none !important;
        font-family: Arial, Helvetica, sans-serif !important;
    }

    input[type=file] {
        cursor: pointer !important;
    }

    input[type=radio] {
        height: unset !important;
        border-radius: 50px !important;
        outline: none !important;
        box-shadow: none !important;
        filter: none !important;
        &:checked{
            background-color: $cor1 !important;
        }
    }

    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    // input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration {
        display: none;
    }

    .form-control:focus {
        box-shadow: none !important;
    }

    input,textarea,select{
        border-bottom: 0;
        transition: all 0.1s ease !important;
        // &:focus{
        //     border-bottom: 2px solid $cor1 !important;
        // }
    }

    .input-group {
        box-shadow: 0px 2px 4px 1px rgb(0 0 0 / 30%);
        @include borderR();
    }

    // z-indez reduzido
    .input-group > .form-control:focus,
    .input-group > .custom-select:focus,
    .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label{
        z-index: 0 !important;
    }

    textarea {
        @include input-primary;
        height: auto !important;
    }

    // vee-validationicon valid removed
    .was-validated .form-control:valid, .form-control.is-valid{
        padding-left:8px;
        padding-right: 0;
        background-image: none;
    }

    //campo com botao cep
    .input-cep{
        border-radius:$borderRadius !important;
    }


    input {
        @include input-primary
    }

    .form-control {
        color: $inputcolor !important;
    }

    .input-cep {
        @include input-cep;
    }
