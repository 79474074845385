.bg-modal {
    height: 100vh;
    width: 100%;
    background-color: rgba($black, .85);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    border: 3px solid $black;
    border-radius: $borderRadius !important;
    background-color: $bgModal !important;
    padding: 0 !important;
    h1,h2,h3{
        color: $white !important;
    }
    p, li, a {
        color: $white !important;
    }
    width: 100%;
    min-width: 400px;
    max-width: 400px;
    @media (max-width: 380px){
        min-width: 250px;
    }
}
//modal global de loading
.modal-loading-global {
    svg {
        width: 50px;
        height: 50px;
        &.fa-spinner {
        }
        path {
            fill: $cor1;
        }
    }

    @keyframes spinner {
        to {
            transform: rotate(360deg);
        }
    }
}


.modal-backdrop {
    opacity: 0.8;
}

.wrapper-icon-modal {

    p{
        cursor: pointer;
        transition: all .7s ease;
        transform-origin: center center;
        &::before{
            font-size: 1.5rem;
            color: $modalClose;
        }
        &:hover{
            transition-delay: .2s;
            transform: scale(1.1);
        }
    }
}
