@import 'variables';

// ===========================================================================

// BORDER RADIUS
@mixin borderR($br:$borderRadius) {
    -webkit-border-radius: $br !important;
    -moz-border-radius: $br !important;
    border-radius: $br !important;
}

// ===========================================================================

// BOX SHADOW
@mixin boxShadow($horz:0px, $vert:2px, $blur:4px, $size:1px, $rgb:rgba(0, 0, 0, 0.3)) {
    -webkit-box-shadow: $horz $vert $blur $size $rgb;
    -moz-box-shadow:    $horz $vert $blur $size $rgb;
    box-shadow:         $horz $vert $blur $size $rgb;
}


// ===========================================================================


// BUTTON
@mixin btn-primary  (
        $heigth: 100%,
        $maxHeight: 54px,
        $padding: 12px 25px,
        $font-size: 1rem, //16px,
        $line-heigth: normal,
    )
{
    @include borderR(50px);
    box-shadow: $boxShadowBtn;
    border: none;
    background: $bgBtn;
    padding: $padding;
    height: $heigth;
    max-height: $maxHeight;
    font-size: $font-size;
    line-height: $line-heigth;
    color: $btColor;
    font-family: $font;
    font-weight: 500;
    position: relative;
    display: flex;
    flex-direction: column;
    width: auto;
    min-width: 250px;
    z-index: 1;
    align-items: stretch;
    justify-content: center;
    margin: 0 auto;
    transition: ease 0.5s;
    font-weight: unset;
    @media (max-width: 400px) {
        font-size: $fontSize;
    }

    a {
        color: $btColor;
        text-decoration: none;

        &:hover, &:focus, &:active {
            color: $cor1;
        }
    }
    &:hover, &:focus {
        background: $bgBtnHover;
        border: none;
        outline: none;
        color: $btColorHover !important;
        box-shadow: $boxShadowBtn !important;
    }

}


// INPUT
@mixin input-primary() {
    @include borderR(8px);
    height: $inputHeight;
    background-color: $inputBg;
    color: $inputcolor !important;
    outline: none;
    border: 1px solid #979797 !important;
    font-size: $fontInput;
    font-family: $font;
    // box-shadow: 0 1px 2px rgba(0,0,0,.05), 0 4px 5px rgba(0,0,0,.05);
    z-index: unset !important;
    padding: 10px;
    &:focus{
        background-color: $inputBg;
    }
    &::placeholder {
        font-family: $font;
        color: rgba($inputcolor, .5) !important;
    }
}

@mixin input-cep() {
    @include borderR(8px);
    height: $inputHeight;
    color: $inputcolor;
    outline: none;
    border: 2px solid #E8E8EB !important;
    font-size: $fontInput;
    box-shadow: 0 1px 2px rgba(0,0,0,.05), 0 4px 5px rgba(0,0,0,.05) !important;
    z-index: unset !important;
    padding: 10px;
    &::placeholder {
        color: rgba($inputcolor, .5) !important;
    }
}

// SELECT
@mixin select-primary() {
    @include borderR();
    height: $inputHeight;
    color: $inputcolor;
    outline: none;
    border: 2px solid #E8E8EB !important;
    font-family: $fontB;
}

// TEXTAREA
@mixin text-area-primary() {
    @include borderR();
    @include boxShadow();
    outline: none;
    border: 2px solid #E8E8EB !important;
    font-size: $fontSize;
    font-family: $fontText;
    padding: 10px;
    &::placeholder {
        color: rgba($inputcolor, .5) !important;
        font-size: $fontSize;
    }

}

