@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@400;500&display=swap');

$cor1               : #FFB929; // amarelo
$cor2               : #979797; // BG Input
$cor3               : #171717; // BG Boxes


$error              : #a90329; // bg msg erro
$white              : #ffffff;
$black              : #000000;

//-> barra de scroll
$corScrollbar       : $cor1;
$corScrollbarBack   : #e0e0e0;

//-> bg's
$bgBody             : rgba($black, 1);

//-> botoes
$bgBtn              : linear-gradient(135.13deg, $cor1 10.46%, #FF7DC1 127.81%);;
    $btColor            : $white;
$bgBtnHover         : #1fbfae;//linear-gradient(0deg, rgb(73, 73, 64) 0%, rgb(31, 31, 31) 63%);
    $btColorHover       : $white;
$bgBtnGradient      : linear-gradient(0deg, rgba(#e39700,1) 0%, rgba(#faeb01,1) 65%);
$boxShadowBtn       : 0 1px 2px rgba(0,0,0,.05), 0 4px 5px rgba(0,0,0,.05);

//-> modais
$bgModal            : $cor3;
$modalClose         : $white;
$corTitleModal      : $white;

//-> titulos
$corTitle           : $white;

//-> textos
$corText            : $white;


//-> layout
$maxWidth           : 1200px;
$menuHeight         : 75px;
$bannerHeight       : 282px;
$borderRadius       : 20px;
$padding            : 40px;
$paddingInternas    : 120px;
$paddingMobileInternas: 40px;

//-> inputs
$inputHeight        : 50px;
$inputBg            : rgba(216, 216, 216, 0.12);
$inputcolor         : $white;
$inputDisabled      : #c5c5c5;
$fontInput          : 1.1rem; //22px;
$labelBg            : $cor1;
$labelTxt           : $cor3;

//-> fonts
$font               : "Urbanist";
$fontB              : "Urbanist";
$fontI              : "Urbanist";
$fontSize           : 1.1rem;//21px
$fontText           : "arial";
$fontMenu           : 1.0625rem; //17px
$fontTitle          : 1.6rem;//24

//-> media querie
$screenXL           : 1250px;
$screenLG           : 992px;
$screenMD           : 850px;
$mobile             : 768px;
$screenSD           : 576px;
