.vs__dropdown-toggle {
    border: 0 !important;
    @include input-primary;
    background-color: $inputBg !important;
    display: flex;
    padding: 0;
    white-space: normal;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.vs__actions{
    align-items: center;
    display: flex;
    padding: 4px 6px 0 3px;
    button{
        border: none;
    }
}

.vs--searchable .vs__dropdown-toggle{
    cursor: pointer !important;
}

.vs--open .vs__open-indicator {
    transform: rotate(180deg) scale(1);
}

.vs__open-indicator{
    fill:$inputcolor !important;
    transition: transform 0.15s cubic-bezier(1,0.5,0.8,1);
}

.v-select {
    height: $inputHeight;
    position: relative;
    .vs__dropdown-toggle{
        border-radius: $borderRadius;
    }
    &.vs--open{
        .vs__dropdown-toggle {
            border-radius: 10px 10px 0 0 !important;
            background: $white;
        }
    }
}

.vs__dropdown-menu {
    font-family: $font !important;
    font-size: 18px;
    background: $white;
    border-radius: 0 0 8px 8px !important;
    height: auto;
    min-height: 104px;
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 100;
    position: absolute;
    width: 100%;
    overflow-x: auto;
}

.vs__dropdown-option{
    color: $cor1;
    list-style: none;
    padding: 0 1rem;
    &:hover{
        background-color:$cor1;
        color: $white;
        width: 100% !important;
        min-width: max-content !important;
    }
    &.vs__dropdown-option--highlight{
        background:$cor1;
        color: $white !important;
        // width: 43vw !important;
        @media(max-width:768px) {
            // width: 100vw !important;
        }
    }
}

.vs__search, .vs__search:focus {
    //font-size: 25px;
    font-size: $fontInput !important;
    margin: 0 !important;
    // display: none;
    border: 0 !important;
    position: absolute;
    // left: 0;
    // width: 100% !important;
    left: 0;
    width: 100% !important;
    box-shadow: none;
    height: 72%;
    max-height: 250px;
    top: 8px;
    background-color: transparent;
    padding: 0 0 0 10px;
}

.vs__selected-options{
    overflow: hidden !important;
    display: flex;
    flex-basis: 100%;
    flex-grow: 1;
    flex-wrap: wrap;
    padding: 0 2px;
    position: relative;
    height: $inputHeight;
}

.vs__selected {
    margin: 0 !important;
    font-size: $fontInput;
    color: $inputcolor !important;
    padding: 0.7em 0.25em 0.8em 0.5em !important;
    line-height: 1.5 !important;
    // max-width: 40ch !important;
    white-space: nowrap !important;
    @media (max-width: 1120px){
        line-height: 2 !important;
    }
}

.vs--single.vs--open .vs__selected{
    position: static !important;
    opacity: .4;
}

.vs--single.vs--searching .vs__selected{
    display: none;
}

.vs__clear {
    box-shadow: none !important;
    background: none !important;
    svg{
        path{
            fill: $white;
        }
    }
}

.vs__no-options{
    text-align: center;
}
